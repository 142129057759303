import { Icons } from '../../snippets/Icons';
import { Schema } from './HoverCards.schema';

export function HoverCards({ cms }) {
  const { heading, cards, gridColumns } = cms;
  let desktopColumnsCss = 'xl:grid-cols-4';
  if (gridColumns === '1') {
    desktopColumnsCss = 'xl:grid-cols-1';
  } else if (gridColumns === '2') {
    desktopColumnsCss = 'xl:grid-cols-2';
  } else if (gridColumns === '3') {
    desktopColumnsCss = 'xl:grid-cols-3';
  }

  return (
    <div className="flex flex-col gap-10 bg-black py-[60px] px-5 md:px-[120px] md:pt-[100px] md:pb-[200px]">
      {heading && (
        <h2 className="max-w-[660px] text-left text-[2.875rem] leading-[48px] tracking-[-0.02em] lg:text-5xl lg:leading-[54px]">
          {heading}
        </h2>
      )}
      {cards?.length > 0 && (
        <div
          className={`grid w-full grid-cols-1 items-start gap-5 lg:grid-cols-2 ${desktopColumnsCss}`}
        >
          {cards.map((card, index) => (
            <div key={`hover-card-${index}`} className="group relative">
              {/* card default */}
              <div className="absolute top-0 left-0 flex h-full w-full flex-col items-start justify-between rounded-3xl bg-grey-900 p-[25px] opacity-100 transition-all group-hover:pointer-events-none group-hover:opacity-0">
                {card.icon && (
                  <Icons
                    icon={card.icon}
                    className="h-11 w-11"
                    style={{
                      color: card.cardColor,
                    }}
                  />
                )}
                {card.heading && (
                  <p className="font-aeonik text-2xl leading-7 text-primary-light">
                    {card.heading}
                  </p>
                )}
              </div>

              {/* card hover */}
              <div
                className="flex min-h-[320px] w-full flex-col items-start justify-between gap-5 overflow-hidden rounded-3xl p-[25px]"
                style={{
                  backgroundColor: card.cardColor || '#161719',
                }}
              >
                {card.description && (
                  <p className="font-aeonik text-[22px] leading-6 text-primary-light md:text-xl">
                    {card.description}
                  </p>
                )}
                <div className="flex flex-col items-start gap-3">
                  <div>
                    {card.profileName && (
                      <p className="font-aeonik leading-[18px] text-primary-light">
                        {card.profileName}
                      </p>
                    )}
                    {card.profileDescription && (
                      <p className="font-aeonik leading-[18px] text-primary-light">
                        {card.profileDescription}
                      </p>
                    )}
                  </div>
                  {card.profileImage?.src && (
                    <img
                      className="h-5 w-auto"
                      src={card.profileImage.src}
                      alt={card.profileImage.alt}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

HoverCards.displayName = 'HoverCards';
HoverCards.Schema = Schema;
