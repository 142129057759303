import { IconSettings } from '../../settings/icons';

export function Schema() {
  return {
    label: 'Product Card',
    key: 'product-card',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Card Heading',
      },
      {
        label: 'Buttons',
        name: 'buttons',
        component: 'group-list',
        fields: [
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          IconSettings,
          {
            label: 'Icon Position',
            name: 'iconPosition',
            component: 'select',
            options: [
              { label: 'Left', value: 'left' },
              { label: 'Right', value: 'right' },
            ],
          },
        ],
        itemProps: {
          label: '{{item.link.text}}',
        },
        validate: {
          maxItems: 2,
        },
        defaultItem: {
          link: {
            text: 'Get Started',
            url: '',
          },
          icon: '',
          iconPosition: 'left',
        },
      },
      {
        label: 'Small text',
        name: 'smallText',
        component: 'text',
      },
      {
        label: 'Desktop Image',
        name: 'imageDesktop',
        component: 'image',
      },
      {
        label: 'Mobile Image',
        name: 'imageMobile',
        component: 'image',
      },
    ],
  };
}
