import { Schema } from './ProductCard.schema';

import { Section } from '../../snippets';
import { Button } from '../../snippets/button';
import { Icons } from '../../snippets/Icons';

export function ProductCard({ cms }) {
  const { heading, buttons, smallText, imageDesktop, imageMobile } = cms;
  return (
    <Section cms={cms}>
      <div className="northern-lights-gradient px-5 py-[60px] lg:px-[60px] lg:py-[100px]">
        <div className="mx-auto flex max-w-[1440px] flex-col items-start gap-10 overflow-hidden rounded-b-[40px] bg-grey-900 py-10 lg:flex-row lg:py-0">
          <div className="flex flex-col items-start gap-10 px-[30px] lg:w-[600px] lg:flex-shrink-0 lg:py-[80px] lg:pl-[60px] lg:pr-0">
            {heading ? (
              <h2 className="font-aeonik text-4xl font-normal text-primary-light lg:text-5xl lg:leading-[1.125]">
                {heading}
              </h2>
            ) : null}
            {(buttons && buttons.length > 0) || smallText ? (
              <div className="flex w-full flex-col gap-3">
                {buttons && buttons.length > 0 ? (
                  <div className="flex w-full flex-col gap-2 md:w-auto md:flex-row md:gap-3">
                    {buttons.map((button, index) => (
                      <Button
                        key={`product-card-button-${index}`}
                        link={button.link}
                        icon={button.icon}
                        iconPosition={button.iconPosition}
                      />
                    ))}
                  </div>
                ) : null}
                {smallText ? (
                  <div className="hidden items-center gap-1 text-grey-400 lg:flex">
                    <Icons icon="infoEmpty" />
                    <p className="font-aeonik text-xs">{smallText}</p>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          <div className="relative flex flex-col">
            {(imageDesktop || imageMobile) && (
              <picture>
                {imageDesktop && imageMobile && (
                  <source
                    srcSet={imageDesktop?.src}
                    media="(min-width: 768px)"
                  />
                )}

                <img
                  className="w-full"
                  src={imageMobile?.src || imageDesktop?.src}
                  alt={imageMobile?.alt || imageDesktop?.alt}
                />
              </picture>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
}

ProductCard.displayName = 'ProductCard';
ProductCard.Schema = Schema;
