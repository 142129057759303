import { useEffect, useState } from 'react';
import { Section } from '../../snippets';
import { Schema } from './FeatureGallery.Schema';

export function FeatureGallery({ cms }) {
  const { heading, features } = cms;
  const [activeIndex, setActiveIndex] = useState(0);
  const defaultSeconds = 5;

  useEffect(() => {
    if (!features || features.length === 0) return () => null;
    const seconds = defaultSeconds;
    const timer = setTimeout(() => {
      setActiveIndex(activeIndex === features.length - 1 ? 0 : activeIndex + 1);
    }, seconds * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [activeIndex]);

  return (
    <Section cms={cms}>
      <div className="bg-black py-[60px] px-5 lg:py-[120px] lg:px-[120px]">
        <div className="mx-auto flex max-w-[1200px] flex-col gap-[35px] lg:gap-[80px]">
          {heading ? (
            <h2 className="text-center text-[2.875rem] leading-[48px] text-primary-light lg:text-[3.5rem] lg:leading-[3.75rem]">
              {heading}
            </h2>
          ) : null}
          {features && features.length > 0 && (
            <div className="flex flex-col gap-[15px] lg:grid lg:h-[560px] lg:grid-cols-8">
              {features.map((feature, index) => {
                const {
                  logo,
                  value,
                  label,
                  description,
                  profileName,
                  profileDescription,
                  profileImage,
                  imageDesktop,
                  imageMobile,
                } = feature;

                return (
                  <button
                    key={`gallery-item-${index}`}
                    className={`rounded-[25px] p-[25px] text-left ${
                      activeIndex === index
                        ? 'bg-grey-800 lg:col-span-5'
                        : 'bg-grey-900 lg:col-span-1'
                    }`}
                    onClick={() => setActiveIndex(index)}
                    type="button"
                  >
                    {activeIndex === index ? (
                      <div className="flex h-full flex-col items-start gap-[50px] lg:grid lg:grid-cols-2">
                        <div className="flex flex-col gap-[30px] lg:h-full">
                          <div className="flex flex-col items-start gap-10">
                            {logo ? (
                              <img
                                src={logo.src}
                                alt={logo.alt}
                                className="h-5 w-auto object-contain"
                              />
                            ) : null}
                            <div className="flex flex-col gap-[15px]">
                              {value ? (
                                <p className="font-aeonik text-[5rem] leading-[0.8] tracking-tighter text-white">
                                  {value}
                                </p>
                              ) : null}
                              {label ? (
                                <p className="font-aeonik text-sm leading-4 text-grey-400">
                                  {label}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="flex flex-col items-start gap-[30px] lg:mt-auto">
                            {description ? (
                              <p className="font-aeonik text-sm leading-[1.125rem] text-grey-200">
                                {description}
                              </p>
                            ) : null}
                            {profileName ||
                            profileDescription ||
                            profileImage?.src ? (
                              <div className="flex items-center gap-2 rounded-full bg-grey-700 py-[5px] px-4 lg:bg-transparent lg:py-0 lg:px-0">
                                {profileImage?.src ? (
                                  <img
                                    src={profileImage.src}
                                    alt={profileImage.alt}
                                    className="-ml-2 h-8 w-8 rounded-full object-cover lg:hidden"
                                  />
                                ) : null}
                                <div className="font-aeonik text-xs leading-[0.875rem] text-grey-300 lg:text-sm lg:leading-4">
                                  {profileName ? <p>{profileName}</p> : null}
                                  {profileDescription ? (
                                    <p>{profileDescription}</p>
                                  ) : null}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {imageDesktop?.src || imageMobile?.src ? (
                          <picture className="aspect-square overflow-hidden rounded-xl lg:aspect-auto lg:h-full">
                            {imageDesktop && imageMobile && (
                              <source
                                srcSet={imageDesktop?.src}
                                media="(min-width: 768px)"
                              />
                            )}
                            <img
                              className="h-full w-full object-cover"
                              src={imageMobile?.src || imageDesktop?.src}
                              alt={imageMobile?.alt || imageDesktop?.alt}
                            />
                          </picture>
                        ) : null}
                      </div>
                    ) : (
                      <div className="flex h-full flex-col items-start gap-[25px]">
                        {logo ? (
                          <img
                            src={logo.src}
                            alt={logo.alt}
                            className="h-5 w-auto object-contain"
                          />
                        ) : null}
                        <div className="flex flex-col gap-[10px] lg:mt-auto">
                          {value ? (
                            <p className="font-aeonik text-4xl leading-none tracking-tighter text-white">
                              {value}
                            </p>
                          ) : null}
                          {label ? (
                            <p className="font-aeonik text-sm leading-4 text-grey-400">
                              {label}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </button>
                );
              })}
            </div>
          )}
          {features && features.length > 0 && (
            <nav className="flex justify-center">
              <div className="inline-flex justify-center gap-2 rounded-lg bg-[rgba(255,255,255,.10)] p-2">
                {features.map((tab, index) => {
                  const isActive = index === activeIndex;
                  return (
                    <button
                      key={`featureTabDesktop-${index}`}
                      type="button"
                      className="flex w-[30px] flex-col gap-5"
                      onClick={() => setActiveIndex(index)}
                    >
                      <div className="progress-bar-light progress-bar-slim mt-auto">
                        {isActive ? (
                          <span
                            className="progress-bar-inner progress-bar-slim progress-bar-animation"
                            style={{
                              animationDuration: `${defaultSeconds}s`,
                              animationIterationcount: 'infinite',
                            }}
                          />
                        ) : null}
                      </div>
                    </button>
                  );
                })}
              </div>
            </nav>
          )}
        </div>
      </div>
    </Section>
  );
}

FeatureGallery.displayName = 'FeatureGallery';
FeatureGallery.Schema = Schema;
