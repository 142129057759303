import { Schema } from './TwoCards.schema';

import { Section } from '../../snippets';
import { Badge } from '../../snippets/badge';
import { Button } from '../../snippets/button';

export function TwoCards({ cms }) {
  const { badge, heading, cards = [] } = cms;
  return (
    <Section cms={cms}>
      <div className="bg-white">
        <div className="mx-auto flex max-w-[1440px] flex-col gap-[50px] py-[60px] px-5 lg:gap-[80px] lg:px-10 lg:pt-[120px] lg:pb-[150px]">
          {badge?.text || heading ? (
            <div className="flex flex-col items-start gap-5 lg:px-[100px]">
              <Badge
                text={badge?.text}
                icon={badge?.icon}
                textColor="#1a1a1a"
                badgeColor="#e6e6e6"
              />
              {heading ? (
                <h2 className="font-aeonik text-4xl font-normal tracking-tight text-primary-dark lg:max-w-[840px] lg:text-5xl lg:leading-[1.125]">
                  {heading}
                </h2>
              ) : null}
            </div>
          ) : null}
          {cards.length ? (
            <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 lg:gap-[30px] lg:px-[60px]">
              {cards.map((card, index) => (
                <div
                  key={`two-cards-card-${index}`}
                  className="flex flex-col gap-3 rounded-xl bg-grey-50 p-4 lg:gap-[25px] lg:p-[30px]"
                >
                  {/* Media Wrapper */}
                  <div className="relative flex flex-col">
                    {card.videoDesktop || card.videoMobile ? (
                      <div className="aspect-video">
                        {card.videoDesktop ? (
                          <video
                            className="hidden h-full w-full object-cover lg:block"
                            poster={card.imageDesktop?.src}
                            autoPlay
                            muted
                            playsInline
                            loop
                          >
                            <source src={card.videoDesktop} type="video/mp4" />
                          </video>
                        ) : null}
                        {card.videoMobile ? (
                          <video
                            className="h-full w-full object-cover lg:hidden"
                            poster={card.imageMobile?.src}
                            autoPlay
                            muted
                            playsInline
                            loop
                          >
                            <source src={card.videoMobile} type="video/mp4" />
                          </video>
                        ) : null}
                      </div>
                    ) : null}

                    {/* Hero Image */}
                    {!card.videoMobile &&
                      !card.videoDesktop &&
                      (card.imageDesktop || card.imageMobile) && (
                        <picture>
                          {card.imageDesktop && card.imageMobile && (
                            <source
                              srcSet={card.imageDesktop?.src}
                              media="(min-width: 768px)"
                            />
                          )}

                          <img
                            className="w-full"
                            src={
                              card.imageMobile?.src || card.imageDesktop?.src
                            }
                            alt={
                              card.imageMobile?.alt || card.imageDesktop?.alt
                            }
                          />
                        </picture>
                      )}
                  </div>

                  {card.heading || card.description ? (
                    <div className="flex flex-col gap-3 pr-3 lg:gap-[25px] lg:pr-[30px]">
                      {card.heading ? (
                        <h3 className="font-aeonik text-[1.75rem] font-normal leading-6 tracking-tight text-primary-dark lg:text-[2.625rem] lg:leading-none">
                          {card.heading}
                        </h3>
                      ) : null}
                      {card.description ? (
                        <p className="font-aeonik text-sm font-normal leading-[18px] tracking-[-0.01em] text-grey-600 lg:max-w-[450px] lg:text-xl lg:leading-7">
                          {card.description}
                        </p>
                      ) : null}
                    </div>
                  ) : null}
                  {card.buttons?.length ? (
                    <div className="flex flex-col gap-2 lg:flex-row lg:gap-3 lg:pr-[30px]">
                      {card.buttons.map((button, i) => (
                        <Button key={`two-cards-button-${i}`} {...button} />
                      ))}
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </Section>
  );
}

TwoCards.displayName = 'TwoCards';
TwoCards.Schema = Schema;
