import { IconSettings } from '../../settings/icons';

export function Schema() {
  return {
    label: 'Logo Marquee V2',
    key: 'logo-marquee-v2',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue:
          'Used in production by the most innovative ecommerce brands.',
      },
      {
        label: 'Background Color',
        name: 'bgColor',
        component: 'color',
        defaultValue: '#000',
      },
      {
        label: 'Logo Color',
        name: 'logoColor',
        component: 'select',
        options: [
          {
            label: 'Black',
            value: 'invert-0',
          },
          {
            label: 'Gray',
            value: 'invert-[50%]',
          },
          {
            label: 'White',
            value: 'invert',
          },
        ],
        defaultValue: 'invert-[50%]',
      },
      {
        label: 'Partners',
        name: 'partners',
        component: 'group-list',
        itemProps: {
          label: '{{item.name}}',
        },
        fields: [
          {
            label: 'Name',
            name: 'name',
            component: 'text',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
        ],
      },
      {
        label: 'Partners subheading',
        name: 'subheading',
        component: 'text',
        defaultValue: 'Loved by marketers and developers',
      },
      {
        label: 'Button',
        name: 'button',
        component: 'group',
        fields: [
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          IconSettings,
          {
            label: 'Icon Position',
            name: 'iconPosition',
            component: 'select',
            options: [
              { label: 'Left', value: 'left' },
              { label: 'Right', value: 'right' },
            ],
          },
        ],
        defaultValue: {
          link: {
            text: 'Explore Case Studies',
            url: '/',
          },
          icon: 'arrowRight',
          iconPosition: 'right',
        },
      },
    ],
  };
}
