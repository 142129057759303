import { IconSettings } from '../../settings/icons';

export function Schema() {
  return {
    label: 'Main Hero V2',
    key: 'main-hero-v2',
    fields: [
      {
        label: 'Heading Line 1',
        name: 'headingLine1',
        component: 'text',
        defaultValue: 'Main Hero Heading',
      },
      {
        label: 'Heading Line 2',
        name: 'headingLine2',
        component: 'text',
        defaultValue: 'Hightlighted Heading',
      },
      {
        label: 'Subheading',
        name: 'subheading',
        component: 'textarea',
        defaultValue: 'Main Hero Subheading.',
      },
      {
        label: 'Buttons',
        name: 'buttons',
        component: 'group-list',
        fields: [
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          IconSettings,
          {
            label: 'Icon Position',
            name: 'iconPosition',
            component: 'select',
            options: [
              { label: 'Left', value: 'left' },
              { label: 'Right', value: 'right' },
            ],
          },
        ],
        itemProps: {
          label: '{{item.link.text}}',
        },
        validate: {
          maxItems: 2,
        },
        defaultItem: {
          // type: 'btn-primary',
          link: {
            text: 'Get Started',
            url: '',
          },
          icon: '',
        },
        defaultValue: [
          {
            link: { text: 'Create an account', url: '' },
            icon: 'arrowRight',
            iconPosition: 'right',
          },
          {
            link: { text: 'Watch a demo', url: '' },
            icon: 'play',
            iconPosition: 'left',
          },
        ],
      },
      {
        label: 'Image (Mobile)',
        name: 'imageMobile',
        component: 'image',
      },
      {
        label: 'Image (Desktop)',
        name: 'imageDesktop',
        component: 'image',
      },
      {
        label: 'Video URL (Mobile)',
        name: 'videoMobile',
        component: 'text',
      },
      {
        label: 'Video URL (Desktop)',
        name: 'videoDesktop',
        component: 'text',
      },
    ],
  };
}
