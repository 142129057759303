import { useSettings } from '@backpackjs/storefront';
import { Schema } from './LogoMarqueeV2.schema';

import { MarqueeComponent } from '../../snippets/marquee';

import { Section } from '../../snippets';
import { Button } from '../../snippets/button';

export function LogoMarqueeV2({ cms }) {
  const {
    heading,
    subheading,
    partners = [],
    bgColor,
    logoColor,
    button,
  } = cms;
  const settings = useSettings();

  const logoArray = settings?.logos?.logoMarquee;

  if (logoArray?.length > 0) {
    return (
      <Section cms={cms}>
        <div
          className="flex flex-col gap-10 py-[60px] md:gap-20 md:py-[120px]"
          style={{
            backgroundColor: bgColor || 'var(--black)',
          }}
        >
          <div>
            <MarqueeComponent
              logoMarquee={logoArray}
              imageClasses={`mx-8 h-5 ${logoColor || 'invert-[50%]'}`}
            />
          </div>

          {(heading || partners?.length > 0) && (
            <div className="flex flex-col gap-10 px-5 md:flex-row md:items-start md:justify-between md:px-[120px]">
              <div className="flex flex-col gap-5 md:max-w-2xl">
                {heading && (
                  <h3 className="font-aeonik text-[32px] font-normal leading-8 text-primary-light">
                    {heading}
                  </h3>
                )}
                {partners?.length > 0 && (
                  <div className="flex flex-col gap-[15px] md:flex-row md:items-center">
                    <ul className="flex">
                      {partners.map(({ name, image }, index) => {
                        if (!image?.src) return null;
                        return (
                          <li className="-mx-1 first:ml-0" key={index}>
                            <img
                              className="h-8 w-8 rounded-full border-2 border-primary-light"
                              src={image?.src}
                              alt={name}
                            />
                          </li>
                        );
                      })}
                    </ul>
                    {subheading && (
                      <p className="font-aeonik text-base text-grey-300">
                        {subheading}
                      </p>
                    )}
                  </div>
                )}
              </div>

              <Button
                link={button.link}
                icon={button.icon}
                iconPosition={button.iconPosition}
              />
            </div>
          )}
        </div>
      </Section>
    );
  }

  return null;
}

LogoMarqueeV2.displayName = 'LogoMarqueeV2';
LogoMarqueeV2.Schema = Schema;
