export const IconSettings = {
  label: 'Icon',
  name: 'icon',
  component: 'select',
  options: [
    {
      label: 'Select Icon',
      value: '',
    },
    {
      label: 'Align Left',
      value: 'alignLeft',
    },
    {
      label: 'Arrow Right',
      value: 'arrowRight',
    },
    {
      label: 'Atom',
      value: 'atom',
    },
    {
      label: 'Bars',
      value: 'bars',
    },
    {
      label: 'Book',
      value: 'book',
    },
    {
      label: 'Brackets',
      value: 'brackets',
    },
    {
      label: 'Chevron Down',
      value: 'chevronDown',
    },
    {
      label: 'Clock',
      value: 'clock',
    },
    {
      label: 'Connected Blocks',
      value: 'connectedBlocks',
    },
    {
      label: 'Copy',
      value: 'copy',
    },
    {
      label: 'Dashboard',
      value: 'dashboard',
    },
    {
      label: 'Disabled',
      value: 'disabled',
    },
    {
      label: 'Elements',
      value: 'elements',
    },
    {
      label: 'Elements Plus',
      value: 'elementsPlus',
    },
    {
      label: 'File',
      value: 'file',
    },
    {
      label: 'Globe',
      value: 'globe',
    },
    {
      label: 'Grid',
      value: 'grid',
    },
    {
      label: 'Hamburger',
      value: 'hamburger',
    },
    {
      label: 'Key',
      value: 'key',
    },
    {
      label: 'Laptop',
      value: 'laptop',
    },
    {
      label: 'Layers',
      value: 'layers',
    },
    {
      label: 'Lightning',
      value: 'lightning',
    },
    {
      label: 'Link',
      value: 'link',
    },
    {
      label: 'Modules',
      value: 'modules',
    },
    {
      label: 'Monitor',
      value: 'monitor',
    },
    {
      label: 'Play',
      value: 'play',
    },
    {
      label: 'Refresh',
      value: 'refresh',
    },
    {
      label: 'Rocket',
      value: 'rocket',
    },
    {
      label: 'Route',
      value: 'route',
    },
    {
      label: 'Script',
      value: 'script',
    },
    {
      label: 'Select',
      value: 'select',
    },
    {
      label: 'Send',
      value: 'send',
    },
    {
      label: 'Share',
      value: 'share',
    },
    {
      label: 'Shopify',
      value: 'shopify',
    },
    {
      label: 'Shopping Cart',
      value: 'shoppingCart',
    },
    {
      label: 'Sliders',
      value: 'sliders',
    },
    {
      label: 'Slides',
      value: 'slides',
    },
    {
      label: 'Stack',
      value: 'stack',
    },
    {
      label: 'Stopwatch',
      value: 'stopwatch',
    },
    {
      label: 'Terminal',
      value: 'terminal',
    },
    {
      label: 'Union',
      value: 'union',
    },
    {
      label: 'User Check',
      value: 'userCheck',
    },
    {
      label: 'Users',
      value: 'users',
    },
    {
      label: 'Verified',
      value: 'verified',
    },
    {
      label: 'Video',
      value: 'video',
    },
    {
      label: 'X',
      value: 'x',
    },
  ],
};
