import { Icons } from './Icons';
import { Link } from './Link';

export function Button({ link, icon, iconPosition, className }) {
  if (!link?.url) return null;

  return (
    <Link
      className={`btn-dark ${className}`}
      href={link.url}
      newTab={link.newTab}
    >
      {iconPosition === 'left' && icon ? (
        <div className="-ml-2 flex h-8 w-8 items-center justify-center">
          <Icons icon={icon} />
        </div>
      ) : null}

      {link.text ? <span>{link.text}</span> : null}

      {iconPosition === 'right' && icon ? (
        <div className="-mr-2 flex h-8 w-8 items-center justify-center">
          <Icons icon={icon} />
        </div>
      ) : null}
    </Link>
  );
}
