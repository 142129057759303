import { IconSettings } from '../../settings/icons';

export function Schema() {
  return {
    label: 'Two Cards',
    key: 'two-cards',
    fields: [
      {
        label: 'Badge',
        name: 'badge',
        component: 'group',
        fields: [
          IconSettings,
          {
            label: 'Text',
            name: 'text',
            component: 'text',
          },
        ],
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Heading',
      },
      {
        label: 'cards',
        name: 'cards',
        component: 'group-list',
        fields: [
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'Description',
            name: 'description',
            component: 'textarea',
          },
          {
            label: 'Buttons',
            name: 'buttons',
            component: 'group-list',
            fields: [
              {
                label: 'Link',
                name: 'link',
                component: 'link',
              },
              IconSettings,
              {
                label: 'Icon Position',
                name: 'iconPosition',
                component: 'select',
                defaultValue: 'left',
                options: [
                  { label: 'Left', value: 'left' },
                  { label: 'Right', value: 'right' },
                ],
              },
            ],
            maxItems: 2,
            itemProps: {
              label: '{{item.link.text}}',
            },
            defaultItem: {
              link: {
                text: 'Button',
                url: '/',
              },
              iconPosition: 'left',
            },
          },
          {
            label: 'Desktop Image',
            name: 'imageDesktop',
            component: 'image',
          },
          {
            label: 'Mobile Image',
            name: 'imageMobile',
            component: 'image',
          },
          {
            label: 'Desktop Video URL',
            name: 'videoDesktop',
            component: 'text',
          },
          {
            label: 'Mobile Video URL',
            name: 'videoMobile',
            component: 'text',
          },
        ],
        maxItems: 2,
        itemProps: {
          label: '{{item.heading}}',
        },
        defaultItem: {
          heading: 'Card Heading',
          description: 'Card Description',
        },
        defaultValue: [
          {
            heading: 'Card Heading 1',
            description: 'Card Description 1',
          },
          {
            heading: 'Card Heading 2',
            description: 'Card Description 2',
          },
        ],
      },
    ],
  };
}
